import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/project'
  },
  {
    path: '/project',
    name: 'ProjectList',
    component: () => import('../views/ProjectList.vue')
  },
  {         
    path: '/detail',
    name: 'ProjectDetail',
    component: () => import('../views/ProjectDetail.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
