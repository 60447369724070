import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tabKey: 'park',
  },
  mutations: {
    SET_TAB_KEY(state, value) {
      state.tabKey = value
    }
  },
  actions: {
    setTabKey({commit}, payload) {
      commit('SET_TAB_KEY', payload)
    }
  }
})
