<!--  -->
<template>
  <transition :css="false">
    <div class="header" ref="header">
      <div class="top">
        <div class="topW">
          <div class="topW_subLeft" @click="backHome">
            <img
              class="topW_subLeft_logo"
              src="../assets/images/logo.png"
              alt=""
            />
            <div class="topW_subLeft_title">西安阎良区楼宇经济信息平台</div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Tabbar",
  data() {
    return {
    };
  },
  
  methods: {
    
    backHome() {
      if (this.$route.path === '/project') return;
      this.$router.push('/');
    },
  },

};
</script>
<style lang="scss">
.header {
  [class*=" el-icon-"],
  [class^="el-icon-"] {
    color: white;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/variable";

.header {
  width: 100vw;
  height: 64px;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  .top {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: rgb(26, 108, 163);
    font-size: $font_medium;

    .topW {
      min-width: $page-width;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_subLeft {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        // margin: 6px 0 6px 40px;
        &_logo {
          width: 45px;
          height: 45px;
        }
        &_title {
          margin-left: 15px;
        }
      }
      &_subRight {
        margin-right: 39px;
        width: 64px;
        height: 32px;
        box-sizing: border-box;
        border: 1px solid rgba($color: #ffffff, $alpha: 0.8);
        text-align: center;
        line-height: 32px;
        border-radius: 100px;
        cursor: pointer;
      }
    }
  }
}
</style>
