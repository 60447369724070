<template>
  <div id="main">
    <Tabbar />
    <div class="app-container">
      <router-view/>
    </div>
  </div>
</template>
<script>
import Tabbar from '@/components/Tabbar.vue'
export default ({
  components: {
    Tabbar
  }
})
</script>

<style lang="scss">
  @import "@/assets/css/variable";
  .app-container {
    padding-top: 64px;
  }
</style>
